(() => {
	const cards = document.querySelectorAll('.card');

	cards.forEach(card => {

		card.addEventListener('click', () => {
			const front = card.querySelector('.card-front');
			const back = card.querySelector('.card-back');
			
			card.classList.toggle('flip');

		});
	})
})();
